// #region Global Imports
import * as React from "react";
import { NextPage } from "next";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Head from "next/head";
// #endregion Global Imports

// #region Local Imports
import { Analytics, SeoService } from "@Services";
import { withTranslation } from "@Server/i18n";
import {
    LandingLayout,
    LandingCard,
    HappyCoupleContainer,
    NewsContainer,
    Section,
    AppAd,
    LoginBox,
    InfoCardContainer,
    RegisterBox,
    ForgotPassword,
    ResetPassword,
} from "@Components";
import { HomeActions, AccountActions } from "@Actions";
import { useScreenSize } from "@Definitions";
import { landingCardStyle, otherCardSectionStyle } from "@Styled";
// #endregion Local Imports

// #region Interface Imports
import { IHomePage, IStore } from "@Interfaces";
import { AccountService } from "@Services";
// #endregion Interface Imports

const Home: NextPage<IHomePage.IProps, IHomePage.InitialProps> = ({ t, meta, slug }) => {
    const { useEffect, useState } = React;
    const home = useSelector((state: IStore) => state.home);
    const dispatch = useDispatch();
    const screenSize = useScreenSize();
    const router = useRouter();

    const [secureKey, setSecureKey] = useState("");

    useEffect(() => {
        const {
            sk,
            st,
            sa_adwordscampaign,
            sa_adwordskeyword,
            sa_adwordsplacement,
            sa_adwordsadgroup,
            sa_adwordsadposition,
        } = router.query;
        if (sk) {
            setSecureKey(sk as string);
        }

        if (st) {
            (async () => {
                const response = await AccountService.LoginWithSecureToken(st as string);
                if (response.Reason === "Success") {
                    dispatch(AccountActions.LoginWithSecureToken(response));
                }
            })();
        }

        console.log(meta);
        if (meta) {
            Analytics.event("Visited Landing Page", {
                sa_adwords_campaign: sa_adwordscampaign,
                sa_adwords_keyword: sa_adwordskeyword,
                sa_adwords_placement: sa_adwordsplacement,
                sa_adwords_adgroup: sa_adwordsadgroup,
                sa_adwords_adposition: sa_adwordsadposition,
                sa_page_url: router.asPath,
                sa_page_type: meta.IsAdwords ? "campaign page" : "seo page",
            });
        }
    }, []);

    useEffect(() => {
        if (secureKey) {
            dispatch(HomeActions.SetTopComponent({ topComponent: "reset" }));
        }
    }, [secureKey, dispatch]);

    useEffect(() => {
        switch (slug) {
            case "uye-ol":
                dispatch(HomeActions.SetTopComponent({ topComponent: "register" }));
                break;
            case "uyelik-girisi":
                dispatch(HomeActions.SetTopComponent({ topComponent: "login" }));
                break;
            case "sifremi-unuttum":
                dispatch(HomeActions.SetTopComponent({ topComponent: "forgot" }));
                break;
            default:
                break;
        }

        dispatch(HomeActions.OnlineUserCount());
    }, [dispatch]);

    const renderTop = (component: string) => {
        switch (component) {
            case "card":
                return <LandingCard t={t} />;
            case "login":
                return <LoginBox t={t} />;
            case "register":
                return <RegisterBox t={t} />;
            case "forgot":
                return <ForgotPassword t={t} />;
            case "reset":
                return <ResetPassword t={t} secureKey={secureKey} />;
            default:
                return <LandingCard t={t} />;
        }
    };

    const headerVisibility = () => {
        const headerlessComponents = ["login", "register", "forgot", "reset"];
        const isHidden =
            headerlessComponents.includes(home.topComponent) && screenSize.includes("small");

        return isHidden;
    };

    const renderHead = () => {
        if (meta) {
            return (
                <Head>
                    <title>{meta.Title}</title>
                    <meta name="keywords" content={meta.Keywords} />
                    <meta name="description" content={meta.Description} />
                    {meta.IsAdwords && <meta name="robots" content="noindex, nofollow" />}
                </Head>
            );
        }

        return <div />;
    };

    return (
        <LandingLayout t={t} hiddenHeader={headerVisibility()}>
            {renderHead()}
            <Section
                bgImage={meta?.Image || "//siberalemstatic.s3.eu-west-1.amazonaws.com/backgrounds/xlarge.jpg"}
                cropped={meta?.Image || "//siberalemstatic.s3.eu-west-1.amazonaws.com/backgrounds/xsmall.jpg"}
                customStyle={
                    home.topComponent === "card" ? landingCardStyle : otherCardSectionStyle
                }
            >
                {renderTop(home.topComponent)}
            </Section>
            <Section className="py-8" centered>
                <HappyCoupleContainer t={t} />
            </Section>
            <Section className="py-8" centered bgImage="/images/landing-happy-couple-bg.png">
                <NewsContainer />
            </Section>
            <Section className="py-8" centered>
                <InfoCardContainer />
            </Section>
            <>
                {!screenSize.includes("small") && (
                    <Section className="py-8" centered bgImage="/images/landing-app-ad-bg.png">
                        <AppAd t={t} />
                    </Section>
                )}
            </>
        </LandingLayout>
    );
};

Home.getInitialProps = async (props: IHomePage.IProps): Promise<IHomePage.InitialProps> => {
    const { slug } = props.query;

    if (slug) {
        const metaResponse = await SeoService.Meta(slug as string);
        if (metaResponse.Active) {
            return {
                namespacesRequired: ["common", "home"],
                meta: metaResponse,
                slug: slug as string,
            };
        }
    }

    return {
        namespacesRequired: ["common", "home"],
        slug: slug as string,
    };
};

const Extended = withTranslation(["common", "home"])(Home);

export default Extended;
